import React, {Component} from 'react'
import './errorNoMobile.scss'
import Triste from '../../images/triste2.png'
class errorNoMobile extends Component{
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <React.Fragment>
                <div class="container-page">
                    <div className="contenedor">
                        <div className="cont-img">
                            <img src={Triste} alt=""/>
                        </div>
                        <div className="cont-info">
                            <span>Lo sentimos pero el sitio al que intenta acceder solo esta disponible para dispositivos moviles.</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }



}
export default errorNoMobile;
