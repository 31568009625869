import React, {Component} from 'react';
import {Page, Card, List, ListItem, Input, Icon, Modal,ProgressCircular} from 'react-onsenui';
import axios from 'axios';
import {isTablet,isBrowser,isMobileOnly} from 'react-device-detect';
import {AuthService} from "../../config";
import {ErrorNoMobile} from  './../../page'
import '../../images/bg-login.jpg';
import Logo from '../../images/logo.png'
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import './login.scss';
import {Main} from './../../page';

class Login extends Component {

    history = null;
    username = React.createRef();
    password = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            stateError:"",
            isModalFail:false,
            isLoginLoad:false,
            stateModal:"",
            classLogin: "fondo-login",
            styleformlogin: "none",
            redirect:false

        }
        this.history = this.props.history;
    }


    componentDidMount() {
        const auth = new AuthService;
        if (auth.check()){
            this.redirect();
        }
    }

    redirect = () => {
        const { navigator } = this.props;
        this.setState({redirect:true});
        setTimeout(() => {
            navigator.pushPage({component: Main, props: { key: 'Main' }});
            this.setState({redirect:false});
        },0);
    };

    handleUserChange = (e) => {
        this.setState({
            email: e.target.value
        })
    };
    handlePasswordChange = (e) => {
        const stateInput=this.state.inputIsVisible;
        const longitudPassword=this.state.password.length;
        this.setState({
            password: e.target.value
        })
    if (stateInput==true && longitudPassword===0){
            this.setState({inputIsVisible:false})
        }

    }
    userLogin = () => {
        // navigation.pus
        let password = this.state.password;
        let username = this.state.email;
        this.setState({isLoginLoad:true});
        if (password.length===0 && username.length===0 ) {
            this.setState({isLoginLoad:false});
            this.setState({isModalFail:true})
        }



        const auth = new AuthService();
        auth.attemp(username, password).then((response) => {
            const {navigator} = this.props;
            this.setState({isLoginLoad:false});
            auth.login(response.user, response.token);
            //Push to Home
            navigator.pushPage({component: Main, props: {key: 'Main'}});
            // navigator.popPage({ component: Login, props: { key: 'Login' } })
        }).catch((error) => {
            this.setState({isLoginLoad:false});
            this.setState({isModalFail:true})
        });
    };
    onCloseModalFail=(e)=>{
        this.setState({isModalFail: false});
        this.setState({password:''});
        this.setState({email:''})
       
    }
    isVisiblePassword=(e)=>{
        const stateInput=this.state.inputIsVisible;
        const longitudPassword=this.state.password.length;
        if (stateInput==false){
            this.setState({inputIsVisible:true})
        }else if (stateInput==true){
            this.setState({inputIsVisible:false})
        }

    }
    handleClick = e => {
        this.setState({classLogin: "fondo-login-activo", styleformlogin: "block"});
        //


    };

    render() {
        const { redirect,isLoginLoad,password,inputIsVisible } = this.state;
        if (isBrowser || isTablet) {
            return <ErrorNoMobile/>

        }
        if (isMobileOnly) {
            return <React.Fragment>

                <Page className={`bg-img-login ${this.state.classLogin}`}>
                    <div id="Login-Page">
                        <div className="cont-title">
                            <img className="img-login" src={Logo} alt=""/>
                            <h2 className="h2-login">Mantenimiento de vehiculos</h2>
                        </div>

                        <button className='btn-iniciar' onClick={this.handleClick} modifier="large--cta">
                            INICIAR SESIÓN
                        </button>
                    </div>
                    <div className="cont-login">
                        <img className="img-login" src={Logo} alt=""/>
                        <h2>Mantenimiento de vehiculos</h2>
                        {
                            isLoginLoad === true ?
                                <ProgressCircular className="Circular-login" indeterminate={true}/>
                                :
                                <Card className="bg-blanco">
                                    <div className="ion-text-center">
                                        <h3>Iniciar Sesi&oacute;n</h3>
                                        <p className="size-13">Ingresa La Informaci&oacute;n Correcta</p>
                                    </div>
                                    <div className="content">
                                        <List>
                                            <ListItem className="cont-input-L">
                                                <Input type="email" value={this.state.username} ref={this.username}
                                                       onChange={this.handleUserChange} name="email" placeholder="Nombre de usuario"
                                                       />
                                                <i className='fa fa-user'></i>
                                            </ListItem>
                                            <ListItem className="cont-input-L">

                                                    <Input value={this.state.password} onChange={this.handlePasswordChange}
                                                           ref={this.password} name="password" placeholder="Contraseña"
                                                           type="password"/>


                                                            <i className='fa fa-lock'></i>



                                            </ListItem>
                                        </List>
                                        {/*<div>*/}
                                            {/*<input type={'checkbox'} className='checkbox-login'/>*/}
                                            {/*<span className="text-checkbox">Recordar contraseña</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </Card>
                        }

                        <button className='button-native' onClick={() => this.userLogin()}> INICIAR
                        </button>
                    </div>

                    <Modal isOpen={this.state.isModalFail}>
                        <div className="modalHistoria">
                            <div className="modal-content-historia">
                                <div className="cont-form">
                                    <i className="fas fa-times-circle fail"></i>
                                    <p className="spanModoalSucces">Error de inicio de sesión </p>
                                    <p className="spanModoalSucces">Usuario y/o Contraseña no es correcta </p>
                                    <div className="alert-dialog-footer">
                                        <button className="btn-aceptar-fail" onClick={this.onCloseModalFail}> Aceptar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {
                        (redirect) &&
                        <Modal isOpen={true}>
                            <span><Icon size={35} spin={true} icon='ion-load-d'></Icon> Rediredting...</span>
                        </Modal>
                    }
                </Page>
            </React.Fragment>
        }

    }
}

export default Login;
