import React, {Component} from 'react';
import {List, PullHook, ProgressCircular, ListItem, ListHeader, Modal} from 'react-onsenui';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import {MaintenanceServices} from "../../config";
import {Popover} from 'react-onsenui';
import Callendar from '../../components/callendar/callendar';
import { Parent } from './../../components/Layout/Layout';
import './home.scss'


class Home extends Component{
    maintenance = new MaintenanceServices();
    calendarRef = React.createRef();
    state = {
        currentMonth:'',
        data:[],
        isOpen: false,
        stadoRequest:'fchInicio',
        pullHookState: 'initial'
    };
    constructor(props) {
        super(props);
        let date = new Date();
        date = this.dateFormant(date);
        this.state.currentMonth = date;
        //
        // this.state = {
        //     currentMonth:'',
        //     data:[],
        //     isOpen: false,
        //     stadoRequest:'fchInicio',
        //     pullHookState: 'initial'
        // };
       
    }

    dateFormant= (fecha)=>{

        const realMonth=fecha.getMonth()+1;
        const day=(fecha.getDate()<10)?'0'+fecha.getDate():fecha.getDate();
        const month=(realMonth<10)?'0'+realMonth:realMonth;

        let date = [fecha.getFullYear(),month,day];
        date=date.join('-');
        return date
    };

    changeMonth = (fecha) => {
        const currentMonth = this.dateFormant(fecha);
        this.setState({currentMonth});
    };

    componentDidMount(){
        this.asyncGetMaintenance();
       
    }


    switchStadoRequest=(arg)=>{

        this.setState({stadoRequest:arg,isOpen: false}, () => {
            this.asyncGetMaintenance();
        })
    };
    asyncGetMaintenance = async () => {
        const {stadoRequest}=this.state;
        this.setState({pullHookState:'action'});
        try{

            const datapeticion = await this.maintenance.getMaintenanceCalendar(stadoRequest);
             const data = Object.keys(datapeticion).map(function(item) {
                return {
                    classNames: datapeticion[item].classNames,
                    date: datapeticion[item].date,
                    rendering:'background',

                }


            });
            this.setState({pullHookState: 'initial'});
            this.setState({data});
        } catch (e) {
            console.error("Problem", e)
        }
    };

    render() {
        const { navigator } = this.props;
        const {pullHookState,data,stadoRequest, currentMonth} = this.state;

        return (
            <Parent navigator={navigator}>
                <PullHook
                    thresholdHeight={130}
                    height={5}
                    onLoad={() => {
                        this.setState({pullHookState: 'action'});
                        this.asyncGetMaintenance();
                    }}
                    fixedContent={false}
                />
                <div className="top_4 inline-flex">
                    <h2> <i className="fas fa-home"></i>Inicio</h2>

                    <span className="fa fa-filter filtro" ref={(btn) => { this.btn = btn; }}
                          onClick={() =>
                              this.setState({target: this.btn, isOpen: true})
                          }></span>
                    <Popover
                        isOpen={this.state.isOpen}
                        onCancel={() => this.setState({isOpen: false})}
                        getTarget={() => this.state.target}
                    >
                        <div>
                            <ul className="cont-categoria">
                                <li  onClick={() => this.switchStadoRequest( 'fchInicio')}><span className="inicio"></span><span className="txt-categoria"> Fecha Inicio</span></li>
                                <li   className="final" onClick={() =>this.switchStadoRequest('fchFin')}> <span className="fin"></span><span className="txt-categoria"> Fecha Fin</span></li>

                            </ul>
                        </div>
                    </Popover>
                </div>
                {
                    pullHookState === 'action' ?
                        <ProgressCircular className="Home-circular" indeterminate={true}/>
                        :
                        (data.length === 0) ?
                            <h3>No existe ningún mantenimiento programado</h3>
                            :
                            <Callendar  ref={this.calendarRef} navigator={navigator}  data={data} stateRequest={stadoRequest} className="Full-CalStyles" />

                }

                {/*<Modal isOpen={pullHookState === 'action'}>*/}
                    {/*<span><Icon size={35} spin={true} icon='ion-load-d'></Icon> Loading data...</span>*/}
                {/*</Modal>*/}
            </Parent>
        )
    }



}

export default Home;
