import React, {Component} from 'react';
import "./notificacion.scss";
import {Button, Col, Row, AlertDialog, AlertDialogButton, Modal, Icon,PullHook,ProgressCircular} from 'react-onsenui'
import {Child} from "..";
import {MaintenanceServices} from "../../config";
import {TrackingCalledar, Fichamanto} from "..";
import {ReactDatez} from 'react-datez'

class Notificacion extends Component {

    navigator;
    maintenance = new MaintenanceServices();
    fchFin = React.createRef();

    state = {
        data: {},
        openDialogFinish: false,
        openDialogChangeDate: false,
        LoadingNotif:false,
        fchInicio: '',
        fchFin: '',
        errorform: {},
        finish: false

    };

    constructor(props) {
        super(props);
        this.navigator = props.navigator;
        this.state.finish = this.props.finish;
        this.state.LoadingNotif = true;
    }

    componentDidMount() {
        this.asyncGetMaintenance();
    }

    asyncGetMaintenance = async () => {
        const {id} = this.props;
        try{
            const data = await this.maintenance.getMaintenanceID(id);
            this.setState({LoadingNotif: false});
            this.setState({data});
        } catch (e) {
            console.error("Problem", e)
        }
    };

    handleDialogFinish = (finish = false) => {
        if (finish) {
            this.setState({LoadingNotif: true});
            const {id} = this.props;
            this.maintenance.deleteMaintenance(id).then((response) => {
                this.setState({data: response});
                this.setState({LoadingNotif: false});
            })
        }
        this.setState({openDialogFinish: false}, () => {
            if (finish) {
                //Reiniciamos el contador, llamando la funcion desde los props de nuestro Padre. Navigator
                const {resetCounter} = this.props.navigator.props;
                resetCounter();

                setTimeout(() => {
                    //Esperamos 500ms para autocerrar la ventana
                    this.navigator.popPage(this);
                }, 500);
            }
        });
    };

    handleDialogChangeDate = (changeDate = false) => {
        if (changeDate) {
            const {idMantenimiento,strTitulo,txtObservaciones,idVehiculo,idPrioridad,idMantenimientoTipo, fchInicio} = this.state.data;
            const fchFin = this.fchFin.current.state.selectedDate;
            this.setState({LoadingNotif: true});
            this.maintenance.putMaintenance(
                idMantenimiento,strTitulo,idVehiculo.idTiposVehiculo,fchInicio,fchFin,txtObservaciones,idVehiculo.idVehiculo,idPrioridad.idPrioridad,
                idMantenimientoTipo).then((response) => {

                this.asyncGetMaintenance();
                //Ocultamos los botones de la aprte inferior
                this.setState({finish: false});
                this.setState({openDialogChangeDate: false});
                this.setState({LoadingNotif: false});
                //Reiniciamos el contador, llamando la funcion desde los props de nuestro Padre. Navigator
                const {resetCounter} = this.props.navigator.props;
                resetCounter();

            }).catch((error) => {
                // console.log(error);
                // this.setState({LoadingNotif: false});
                const errorForm = {...this.state.errorform};
                this.setState({
                    errorform: {
                        ...error,
                    },
                });
            });
        } else{
            this.setState({openDialogChangeDate: false});
            this.setState({LoadingNotif: false});
            this.setState({errorform: {}});
            this.setState({fchFin:''});
        }

    };

    handleReactDatez = (input, value) => {
        let setState = {};
        switch(input){
            case 'fchInicio':
                setState.fchInicio = value;
            break;
            case 'fchFin':
                setState.fchFin = value;
            break;
            default :
                return false;
        }

        this.setState(setState);
    };

    render() {
        const {navigator, title} = this.props;
        const ErrorForm=this.state.errorform.fchFin?this.state.errorform.fchFin:null;
        const ErrorFormTipoVehiculo=this.state.errorform.idTiposVehiculo?this.state.errorform.idTiposVehiculo:null;
        console.log(ErrorFormTipoVehiculo)
        console.log(ErrorForm)
        const {data, openDialogFinish, openDialogChangeDate, fchFin, finish,LoadingNotif} = this.state;
        return (
            <Child navigator={navigator} title={title}>

                <PullHook
                    thresholdHeight={130}
                    height={5}
                    onLoad={() => {
                        this.setState({LoadingNotif: true});
                        this.asyncGetMaintenance();
                    }}
                    fixedContent={false}
                />
                {
                    LoadingNotif === true ?
                        <ProgressCircular className="Circular-Notif" indeterminate={true}/>
                        :
                        <div>
                            Notificacion
                            {data.fchInicio &&
                            <TrackingCalledar
                                data={data}
                            />}

                            <Fichamanto
                                datos={data}
                            />
                        </div>

                }
                {/*<div>*/}
                    {/*<TrackingCalledar*/}
                        {/*data={data}*/}
                    {/*/>*/}

                    {/*<Fichamanto*/}
                        {/*datos={data}*/}
                    {/*/>*/}
                {/*</div>*/}


                {
                    finish &&
                    <Row>
                        <Col>
                            <Button modifier="large--cta" onClick={() => this.setState({openDialogChangeDate: true})}>
                                Extender Fecha
                            </Button>
                            <div style={{paddingBottom: '3%'}}></div>
                            <Button modifier="large--cta" onClick={() => this.setState({openDialogFinish: true})}>
                                Finalizar Mantenimiento
                            </Button>
                        </Col>
                    </Row>
                }

                <AlertDialog isOpen={openDialogFinish}>
                    <div className="alert-dialog-title">Alerta!</div>
                    <div className="alert-dialog-content">
                        ¿Estas seguro que deseas terminar con este mantenimiento?


                    </div>
                    <div className="alert-dialog-footer">
                        <AlertDialogButton onClick={() => this.handleDialogFinish()}>
                            No
                        </AlertDialogButton>

                        <AlertDialogButton onClick={() => this.handleDialogFinish(true)}>
                            Si
                        </AlertDialogButton>
                    </div>
                </AlertDialog>

                <Modal isOpen={openDialogChangeDate}>
                    <div className="modal" id="modal">
                        <div className="modal-content-notification">
                            <div className="tituloModal">
                                <h2><i className="far fa-edit"></i> Extender Mantenimiento</h2>
                            </div>
                            <form>
                                <div className="cont-form">
                                    <div className="cont-input cont-flex">
                                        <table style={{width:'100%'}}>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div className="fch-inicio">
                                                        <label>Fecha de inicio</label>
                                                        <input type="text" readOnly={true} defaultValue={data.fchInicio}/>
                                                    </div>
                                                </td>


                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="fch-fin">
                                                        <label>Fecha Fin</label>
                                                        <ReactDatez
                                                            yearJump={false}
                                                            dateFormat='YYYY-MM-DD'
                                                            name="fchFin"
                                                            handleChange={(e) => this.handleReactDatez('fchFin',e)}
                                                            value={fchFin}
                                                            ref={this.fchFin}
                                                        />
                                                    </div>
                                                    {
                                                        ErrorForm === null ?
                                                            <span></span>
                                                            :
                                                            <span className="spanerror" style={{color: 'red'}}>{ErrorForm}</span>
                                                    }
                                                    {
                                                        ErrorFormTipoVehiculo === null ?
                                                            <span></span>
                                                            :
                                                            <span className="spanerror" style={{color: 'red'}}>{ErrorFormTipoVehiculo}</span>
                                                    }

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                        <div className="alert-dialog-title"></div>
                                        <div className="alert-dialog-content">



                                        </div>
                                        <div className="alert-dialog-footer">
                                            <AlertDialogButton onClick={() => this.handleDialogChangeDate(false)}>
                                            Cancelar
                                            </AlertDialogButton>

                                            <AlertDialogButton onClick={() => this.handleDialogChangeDate(true)}>
                                            Extender
                                            </AlertDialogButton>
                                        </div>

                                    {/*<div className="alert-dialog-footer">*/}
                                        {/*<AlertDialogButton onClick={() => this.handleDialogChangeDate()}>*/}
                                            {/*Cancelar*/}
                                        {/*</AlertDialogButton>*/}

                                        {/*<AlertDialogButton onClick={() => this.handleDialogChangeDate(true)}>*/}
                                            {/*Extender*/}
                                        {/*</AlertDialogButton>*/}
                                    {/*</div>*/}
                                </div>

                            </form>

                        </div>
                    </div>
                </Modal>

            </Child>
        )
    }

}

export default Notificacion;
