import React from 'react';
import './slidermanto.scss';
import FichaDetalle from '../../components/fichadetalle/fichadetalle'
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import {Notifiacion} from "../index";
import Checkimg from  '../../images/check.png';
const sliderManto = props => {

    const  handleclickmanto = () => {
        const {navigator}=props;
        let datos=props.datos;
        let key=props.datos.strFolio;
        let id=props.datos.idMantenimiento;
        let title = `Mantenimiento ${key}`;
        navigator.pushPage({component: FichaDetalle, props: { key, id,title,datos }});
    };

    const datos=props.datos
    return (
        <div  onClick={handleclickmanto} id="ConCarusel" className="ver-menos" >
            <div className="item-carosel">
                <div className="cont-info-item">

                    <ul>
                        <li className="titulo-vehiculo"><b>{datos.strTitulo}</b></li>
                        <li>N° económico: <b>{datos.strFolio}</b></li>
                        <li>Fecha Inicio: {datos.fchInicio}</li>
                        <li>Fecha fin:  {datos.fchFin}</li>
                    </ul>

                </div>
                <div className={`bg-${datos.image}`}></div>
        </div>
        </div>);
}
export default sliderManto