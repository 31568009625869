import React from 'react';
import './historymanto.scss'
import Checkimg from  '../../images/check.png';
import FichaDetalle from "../fichadetalle/fichadetalle";
import FichaDetalleComponent from '../../components/fichadetalle/fichadetalle'
const historymanto = props => {
    const  handleclickmanto = () => {
        const {navigator}=props;
        let datos=props.datos;
        let key=props.datos.strFolio;
        let id=props.datos.idMantenimiento;
        let title = `Mantenimiento ${key}`;
        navigator.pushPage({component: FichaDetalleComponent, props: { key, id,title,datos }});
    };

    const datos=props.datos;

    // console.log(datos)
    // const idRequets=props.datos
    const idVehicle=datos.idEstadosVehiculo.idestadosvehiculo;

    return (
         <div  onClick={handleclickmanto}>
             <div className="iten-manto">
                 <div className="cont-flex">
                     {
                         idVehicle === 1 ?

                             <div className={`storycom${datos.idPrioridad.idPrioridad}`}><span></span></div>
                             :
                             <div></div>


                     }
                     {/*<div className={`storycom${datos.idPrioridad.idPrioridad}`}><span></span></div>*/}
                     <div className="cont-txt-manto">
                         <table>
                             <tbody>
                             <tr>
                                 <td colSpan="2"><span className="txt-small">N° orden: {datos.strFolio}</span></td>
                             </tr>
                             <tr>
                                 {/*<td>&nbsp;</td>*/}
                             </tr>
                             <tr>
                                 <td colSpan="2"><b className="titulo" style={{fontSize:'18px'}}>{datos.strTitulo}</b></td>
                             </tr>
                             <tr>
                                 <td colSpan="2">{datos.idVehiculo.strName}</td>
                             </tr>
                             <tr>
                                 <td colSpan="2">Fecha Inicio: <b>{datos.fchInicio}</b></td>
                             </tr>
                             <tr>
                                 <td colSpan="2">Fecha Fin: <b>{datos.fchFin}</b></td>
                             </tr>
                             <tr>
                                 <td colSpan="2">N° económico: <b>{datos.idVehiculo.strPlaca}</b></td>
                             </tr>
                             <tr>


                                     <td colSpan="2">
                                         <p>{datos.txtObservaciones} </p>
                                     </td>

                                 {/*<td colSpan="2">*/}


                                 {/*</td>*/}
                             </tr>
                             </tbody>
                         </table>
                     </div>
                     {
                         idVehicle === 1 ?
                             <div className="concluido"  style={{visibility:'hidden'}}><span><img alt="manto-check" src={Checkimg}/></span></div>
                             :
                             <div className="concluido"  style={{visibility:'block'}}><span><img alt="manto-check" src={Checkimg}/></span></div>

                     }
                     {/*<div className="concluido"  style={{visibility:'hidden'}}><span><img alt="manto-check" src={Checkimg}/></span></div>*/}
                 </div>
             </div>
         </div>);
}
export default historymanto