import React, {Component} from 'react';
import './images/bg-login.jpg';
import { Navigator } from 'react-onsenui';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import './App.css';
import { Login } from './page';


class App extends Component {

    state={
        isCounterReset: false
    };

    resetCounter = () => {
        this.setState({isCounterReset: true}, () => {
            this.setState({isCounterReset: false});
        });
    };

    renderPage = (route, navigator) => {
        route.props = route.props || {};
        route.props.navigator = navigator;
        return React.createElement(route.component, route.props);
    };

    render() {
        return (
            <Navigator
                renderPage={this.renderPage}
                initialRoute={{component: Login, props: { key: 'Login' }}}
                resetCounter={this.resetCounter}
                isCounterReset={this.state.isCounterReset}
            />
        );
    }
}

export default App;
