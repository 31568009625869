import React, {Component} from 'react';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';

import {Modal,ProgressBar,ProgressCircular,PullHook} from 'react-onsenui';
import {Child} from "..";
import Fichamanto from '../fichamanto/fichamanto';
import { TrackingCalledar } from "..";
import '../TrackingCallendar/TrackingCallendar.scss';
import {ReactDatez} from 'react-datez'
import 'react-datez/dist/css/react-datez.css'
import "./fichadetalle.scss";
import { MaintenanceServices, VehicleServices,NotificationServices, VehicleTypeServices, MaintenancePriorityServices,MaintenanceTypeServices } from "../../config";

class FichaDetalle extends Component {

    maintenance = new MaintenanceServices();
    vehicleType= new VehicleTypeServices();
    priorityManto= new MaintenancePriorityServices();
    maintenanceType = new MaintenanceTypeServices();
    notification=new NotificationServices();

    state={
        data:[],
        isLoadingVehicleType:false,
        isLoadingManteType: false,
        isLoadingCallendarFichaDetalle:false,
        isLoadingMapdetail:false,
        isLoadingPriority: false,
        isLoadingForm:false,
        isModalSucess:false,
        idPrioridad:'',
        idMantoType:'',
        isOpen: false,
        errorform: {},
        tipoVehiculo: {},
        isLoadingMante:false,
        MantoTypes:{},
        MantoPriorityState:'Sin filtro',
        Vehicules:{},
        idMantenimiento:{},
        MantoPriorityList:[],
        MantoPriority:{},
        idMantenimientoTipo:"",
        txtobservaciones:"",
        txtTitulo:"",
        Prioritys: {}
    }


    constructor(props){
        super(props)
        const { id } = props;

        this.state.idMantenimiento=id;
        const mant = new MaintenanceServices();
        this.state.isLoadingCallendarFichaDetalle=true;

        mant.getMaintenanceID(id).then((response) => {
            this.setState({data:response});
            this.setState({idPrioridad:this.state.data.idPrioridad.idPrioridad});
            this.state.isLoadingCallendarFichaDetalle=false;
            this.setState({txtobservaciones:response.txtObservaciones});
            this.setState({txtTitulo:response.strTitulo});
            
            this.setState({dateInput1: response.fchInicio});
            this.setState({dateInput2: response.fchFin});
            this.setState({idMantenimiento:response.idMantenimiento});
            this.setState({idMantoType:response.idMantenimientoTipo});


        });


        this.strTitulo = React.createRef();
        this.idTiposVehiculo = React.createRef();
        this.fchInicio = React.createRef();
        this.idMantenimientoTipo = React.createRef();
        this.fchFin = React.createRef();
        this.txtObservaciones = React.createRef();
        this.idVehiculo = React.createRef();
        this.idPrioridad = React.createRef();
        this.formtop= React.createRef();


    }


///Funciones asincronicas
asynGetMantoID = async () => {

    this.setState({isLoadingVehicleType: true});
    // this.setState({pullHookState: 'action'});
    try{
        const id =this.state.idMantenimiento;
        const datapeticion = await this.maintenance.getMaintenanceID(id);
        // this.state.isLoadingCallendarFichaDetalle=false;
        this.setState({data:datapeticion});
        this.state.isLoadingCallendarFichaDetalle=false;
        this.setState({txtobservaciones:datapeticion.txtObservaciones});
        this.setState({txtTitulo:datapeticion.strTitulo});
        
        this.setState({dateInput1: datapeticion.fchInicio});
        this.setState({dateInput2: datapeticion.fchFin});
        this.setState({idMantenimiento:datapeticion.idMantenimiento});

        // this.setState({isModalSucess: false});
        this.setState({isLoadingVehicleType: false});
        // this.setState({tipoVehiculo: datapeticion});
        // this.setState({datapeticion});


    } catch (e) {
        console.error("Problem", e)
    }
};
    asyncNotify = async () => {
        try {
            const notifications = await this.notification.getNotifications();
            this.setState({notifications});
            this.setCounter(notifications.length);
            this.setState({LoadingNotif: false});
            this.setState({pullHookState: 'initial'});
        } catch (e) {
            console.error("Problem", e)
        }
    };
    asynGetVehicle = async () => {

        this.setState({isLoadingVehicleType: true});
        // this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.vehicleType.getVehicleType();
            this.setState({isLoadingVehicleType: false});
            this.setState({tipoVehiculo: datapeticion});
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };

    asynGetPriorityManto  = async () => {
        this.setState({isLoadingPriority: true});
        // this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.priorityManto.getMaintenancePriority();
            this.setState({isLoadingPriority: false});
            this.setState({Prioritys: datapeticion},()=>{
                const {idPrioridad}=this.state.data.idPrioridad
               this.idPrioridad.current.value=idPrioridad
            });
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };

    asynGetMaintenanceType  = async () => {
        this.setState({isLoadingManteType: true});
        const idTiposManto = this.idTiposVehiculo.current.value;
        // this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.maintenanceType.getMaintenanceType(idTiposManto);
            this.setState({isLoadingManteType: false});
            this.setState({idMantenimientoTipo: datapeticion});

            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };


///
    componentDidUpdate(prevProps, prevState) {
        //esta accion es para rellenar este formulario
        // this.setState({isLoadingVehicleType: true});
        // this.setState({isLoadingManteType: true});
        // this.setState({isLoadingPriority: true});

        if (this.state.isOpen && !prevState.isOpen) {
            this.asynGetVehicle();

            // const vehicleType = new VehicleTypeServices();
            // vehicleType.getVehicleType().then((response) => {
            //     this.setState({tipoVehiculo: response});
            //
            // }).catch((error) => {
            //
            // });
            this.asynGetPriorityManto();
            // const priorityManto = new MaintenancePriorityServices();
            // priorityManto.getMaintenancePriority().then((response) => {
            //     this.setState({Prioritys: response});
            // }).catch((error) => {
            //
            // });
            this.asynGetMaintenanceType();
            // const MantenanceType = new MaintenanceTypeServices();
            // MantenanceType.getMaintenanceType().then((response) => {
            //     this.setState({idMantenimientoTipo: response});
            //
            // }).catch((error) => {
            //
            // });
        }

    }
    onCloseModalSucess=(e)=>{
        this.setState({isModalSucess: false});

    }
    handleChangeTipoVehiculo = e => {
        const {name} = e.target;

        if (name ==='idTiposVehiculo'){
            let idTiposVehiculo = this.idTiposVehiculo.current.value;
            const vehicle = new VehicleServices();
            vehicle.getVehicle(idTiposVehiculo).then((response) => {
                this.setState({Vehicules: response});

            }).catch((error) => {
                
            });
        } else if(name==='txt-obser'){
            this.setState({txtobservaciones: e.target.value});

        } else if(name==='strTitulo'){
            this.setState({txtTitulo: e.target.value});

        } else if (name==='idPrioridad'){
            this.setState({idPrioridad:e.target.value})
        } else if (name==='idTipoMantenimiento'){
            this.setState({idMantoType:e.target.value})
        }
        else{

        const errorform = {...this.state.errorform};


        let currentErrors = Object.keys(errorform)
            .filter((key) => (key !== name))
            .reduce((obj, key) => {
                obj[key] = errorform[key];
                // console.log(obj[key]);
                return obj;
            }, {});

        this.setState({errorform: currentErrors});
        }
    };
    handleFchIni = e => {

        this.setState({dateInput1: e});
    };
    handleFchFin = e => {
        this.setState({dateInput2: e});

    }

    onClose = (e) => {
        this.setState({isOpen: false,});
        this.formtop.current.scrollTo(0, 0);
        this.setState({errorform:{}});
        this.setState({dateInput1:this.state.data.fchInicio});
        this.setState({dateInput2:this.state.data.fchFin})



    };
    resetForm=(e)=>{
        this.setState({strTitulo:'', tipoVehiculo: {},Prioritys: {},Vehicules: {},idTipoVehiculo: "",idMantenimientoTipo:"",dateInput1:'',dateInput2:''});
        this.setState({pullHookState: 'initial'});
        this.asynGetVehicle();
        this.asynGetPriorityManto();
        this.asynGetMaintenanceType();
        this.txtObservaciones.current.value='';

    };
    editMaintenance = (e) => {
        e.preventDefault();
        this.setState({isLoadingForm:true});
        const maintenance = new MaintenanceServices();
        let idMantenimiento=this.state.idMantenimiento;
        let strTitulo = this.strTitulo.current.value;
        let idTiposVehiculo = this.idTiposVehiculo.current.value;
        let fchInicio=this.fchInicio.current.state.selectedDate===''?this.state.data.fchInicio:this.fchInicio.current.state.selectedDate;
        let fchFin=this.fchFin.current.state.selectedDate===''?this.state.data.fchFin:this.fchFin.current.state.selectedDate;



        // let fchInicio = this.fchInicio.current.state.selectedDate;
        // let fchFin = this.fchFin.current.state.selectedDate;
        let txtObservaciones = this.txtObservaciones.current.value;
        let idVehiculo = this.idVehiculo.current.value;
        let idPrioridad = this.idPrioridad.current.value;
        let idMantenimientoTipo = this.idMantenimientoTipo.current.value;
        //
        maintenance.putMaintenance(idMantenimiento,strTitulo,idTiposVehiculo,fchInicio,fchFin,txtObservaciones,
            idVehiculo,idPrioridad,idMantenimientoTipo).then((response) => {
            this.setState({isLoadingForm:false});
            this.setState({ isOpen: false});
            this.asynGetMantoID();
            this.resetForm();
            this.setState({isModalSucess: true});
        }).catch((error) => {
            // console.log(error);
            this.setState({isLoadingForm: false});
            const errorForm = {...this.state.errorform};
            this.setState({
                errorform: {
                    ...error,
                },
            });
        });


    };


    render() {
        const {navigator, title} = this.props;
        const {data, idPrioridad} = this.state;
        const {tipoVehiculo, Prioritys, Vehicules, idMantenimientoTipo, MantoPriorityList, isLoadingVehicleType, isLoadingManteType, isLoadingPriority, isLoadingCallendarFichaDetalle, isLoadingForm} = this.state;
        const dateStateInicin = this.state.data ? this.state.data.fchInicio : null;
        const dateStateFin = this.state.data ? this.state.data.fchFin : null;

        const {strTitulo, fchInicio, fchFin} = this.state.errorform;
        const idTipoVehError =this.state.errorform.idTiposVehiculo?this.state.errorform.idTiposVehiculo:null;
        const idMantoType = (data.idMantenimientoTipo) ? data.idMantenimientoTipo : null;
        const idPrioridadState =idPrioridad ? this.state.idPrioridad : null;
        const idMantoTypeState=idMantoType? this.state.idMantoType:null;
        const idVehiculo = (data.idVehiculo) ? data.idVehiculo.idVehiculo : null;
        const idTiposVehiculo = (data.idVehiculo) ? data.idVehiculo.idTiposVehiculo.idvehiculostipo : null;
        const idstrTiposVehiculo = (data.idVehiculo) ? data.idVehiculo.idTiposVehiculo.strName : null;
        const idstrVehiculo = (data.idVehiculo) ? data.idVehiculo.strPlaca : null;
        const progressStyle = {
            marginLeft: '45%',
            marginTop: '63%',
            width: '6vh',
        };

        return (
            <Child navigator={navigator} title={title}>
                <PullHook
                    thresholdHeight={130}
                    height={5}
                    onLoad={() => {
                        this.setState({isLoadingCallendarFichaDetalle: true});
                        this.asynGetMantoID();
                    }}
                    fixedContent={false}
                />
                {
                    isLoadingCallendarFichaDetalle === true ?
                        <ProgressCircular style={progressStyle} ClassName="progresscircdetalle" indeterminate={true}/>
                        :
                        <div>
                            <TrackingCalledar
                                data={data}
                            />
                            <Fichamanto datos={data} />
                            <button style={{width: "100%",marginTop:'22%'}} className='btn-aceptar btn-aceptar-detalle' onClick={e => {
                                this.setState({target: this.btn, isOpen: true})
                            }}>Editar</button>
                        </div>


                }

                <Modal isOpen={this.state.isOpen}>

                    <div className="modal" id="modal">
                        <div ref={this.formtop} className="Modal-content">
                            <div className="tituloModal">
                                <h2><i className="fa fa-edit"></i> Editar mantenimiento</h2>
                            </div>
                            <form  onSubmit={this.editMaintenance}>
                                {
                                    isLoadingForm === true ?
                                        <ProgressCircular className="Circular-form" indeterminate={true}/>
                                        :
                                        <div >
                                        <div className="cont-form">
                                            <div className="cont-input">
                                                <label>Título</label>
                                                {/* <textarea name="txt-obser" value={this.state.txtobservaciones} ref={this.txtObservaciones}   onChange={(e) => this.handleChangeTipoVehiculo(e)}></textarea> */}
                                                <input value={this.state.txtTitulo} ref={this.strTitulo} type="text" name='strTitulo'
                                                       onChange={(e) => this.handleChangeTipoVehiculo(e)}/>

                                            </div>
                                            {strTitulo &&
                                            strTitulo.map((data) => (
                                                <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                            ))

                                            }

                                            <div className="cont-input">
                                                <label>Tipo de vehiculo</label>
                                                <select  defaultValue={0}  ref={this.idTiposVehiculo}
                                                         onChange={(e) => this.handleChangeTipoVehiculo(e)} name="idTiposVehiculo"
                                                         form="carform">
                                                    <option value={idTiposVehiculo}   >{idstrTiposVehiculo}</option>

                                                </select>
                                            </div>
                                            {idTipoVehError &&
                                            idTipoVehError.map((data) => (
                                                <div className="spanerror" style={{color: 'red'}}>{data}</div>
                                            ))

                                            }

                                            <div className="cont-input">
                                                <label>N° económico</label>
                                                <select  defaultValue={0} ref={this.idVehiculo}
                                                         onChange={(e) => this.handleChangeTipoVehiculo(e)} name="idTiposVehiculo"
                                                         form="carform">
                                                    <option value={idVehiculo}   >{idstrVehiculo}</option>
                                                </select>
                                            </div>

                                            <div className="cont-input">
                                                <label>Prioridad</label>
                                                {
                                                    isLoadingPriority === true ?
                                                        <ProgressBar indeterminate={true}/>
                                                        :
                                                        <select ref={this.idPrioridad} name="idPrioridad" form="carform" value={this.state.idPrioridad}
                                                                onChange={(e) => this.handleChangeTipoVehiculo(e)}>
                                                            {/*<option value={idPrioridad} disabled={true}>Seleccione una prioridad</option>*/}
                                                            {(Prioritys.length > 0) &&
                                                            Prioritys.map((data) => (
                                                                <option key={data.idPrioridad}
                                                                        value={data.idPrioridad}>{data.strNombre}</option>
                                                            ))

                                                            }
                                                        </select>
                                                }

                                            </div>

                                            <div className="cont-input">
                                                <label>Tipo de mantenimiento</label>
                                                {
                                                    isLoadingManteType === true ?
                                                        <ProgressBar indeterminate={true}/>
                                                        :
                                                        <select name="idTipoMantenimiento" value={idMantoTypeState}  ref={this.idMantenimientoTipo} onChange={(e) => this.handleChangeTipoVehiculo(e)}
                                                                form="carform">
                                                            {/*<option value={0} disabled={true}>Seleccione un Tipo de Mantenimiento</option>*/}
                                                            {(idMantenimientoTipo.length > 0) &&
                                                            idMantenimientoTipo.map((data) => (
                                                                <option key={data.idMantenimientoTipo}
                                                                        value={data.idMantenimientoTipo}>{data.strNombre}</option>
                                                            ))

                                                            }
                                                        </select>

                                                }

                                            </div>
                                            <div className="cont-input">
                                                <div className="fch-inicio">
                                                    <label>Fecha de inicio</label>
                                                    <ReactDatez yearJump={false} locale={'es'} defaultMonth={this.state.dateInput1} ref={this.fchInicio} dateFormat='YYYY-MM-DD' name="dateInput1"
                                                                handleChange={(e) => this.handleFchIni(e)}
                                                                value={this.state.dateInput1}/>
                                                </div>
                                                {fchInicio &&
                                                fchInicio.map((data) => (
                                                    <div className="spanerror" style={{color: 'red'}}>{data}</div>
                                                ))

                                                }
                                            </div>
                                            <div className="cont-input">
                                                <div className="fch-fin">
                                                    <label>Fecha Fin</label>
                                                    <ReactDatez  yearJump={false} locale={'es'} defaultMonth={this.state.dateInput2} ref={this.fchFin}  dateFormat='YYYY-MM-DD'  name="dateInput2"
                                                                handleChange={(e) => this.handleFchFin(e)}
                                                                value={this.state.dateInput2}/>
                                                </div>
                                                {fchFin &&
                                                fchFin.map((data) => (
                                                    <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                                ))

                                                }
                                            </div>
                                            {/*<div className="cont-input cont-flex">*/}
                                                {/*<table>*/}
                                                    {/*<tbody>*/}
                                                    {/*<tr>*/}
                                                        {/*<td>*/}
                                                            {/*<div className="fch-inicio">*/}
                                                                {/*<label>Fecha de inicio</label>*/}
                                                                {/*<ReactDatez ref={this.fchInicio} dateFormat='YYYY-MM-DD' name="dateInput1"*/}
                                                                            {/*handleChange={(e) => this.handleFchIni(e)}*/}
                                                                            {/*value={this.state.dateInput1}/>*/}
                                                            {/*</div>*/}
                                                            {/*{fchInicio &&*/}
                                                            {/*fchInicio.map((data) => (*/}
                                                                {/*<div className="spanerror" style={{color: 'red'}}>{data}</div>*/}
                                                            {/*))*/}

                                                            {/*}*/}
                                                        {/*</td>*/}
                                                        {/*<td>*/}
                                                            {/*<div className="fch-fin">*/}
                                                                {/*<label>Fecha Fin</label>*/}
                                                                {/*<ReactDatez ref={this.fchFin}  dateFormat='YYYY-MM-DD'  name="dateInput2"*/}
                                                                            {/*handleChange={(e) => this.handleFchFin(e)}*/}
                                                                            {/*value={this.state.dateInput2}/>*/}
                                                            {/*</div>*/}
                                                            {/*{fchFin &&*/}
                                                            {/*fchFin.map((data) => (*/}
                                                                {/*<span className="spanerror" style={{color: 'red'}}>{data}</span>*/}
                                                            {/*))*/}

                                                            {/*}*/}
                                                        {/*</td>*/}
                                                    {/*</tr>*/}
                                                    {/*</tbody>*/}
                                                {/*</table>*/}
                                            {/*</div>*/}

                                            <div className="cont-input">
                                                <label>Observaciones</label>
                                                <textarea name="txt-obser" value={this.state.txtobservaciones} ref={this.txtObservaciones}   onChange={(e) => this.handleChangeTipoVehiculo(e)}></textarea>
                                            </div>

                                        </div>
                                        <div className="actions">
                                    <button className="toggle-button btn-cancelar" onClick={this.onClose} type='button'>Cancelar</button>
                                    <button className="btn-aceptar" type="submit"><i
                                    className="fa fa-edit"></i> Guardar
                                    </button>
                                    </div>
                                    </div>
                                }
                            </form>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isModalSucess}>
                    <div className="modalHistoria">
                        <div className="modal-content-historia">
                            <div className="cont-form">
                                <i className="fas fa-check check"></i>
                                <p className="spanModoalSucces">Mantenimiento editado exitosamente</p>
                                <div className="alert-dialog-footer">
                                    <button className="btn-aceptar" type="button" onClick={this.onCloseModalSucess}> Aceptar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<span> <i className="fas fa-check-circle"></i></span>*/}
                    {/*<span className="spanModoalSucces">Mantenimiento editado exitosamente</span>*/}
                    {/*<button onClick={this.onCloseModalSucess}>Aceptar</button>*/}
                </Modal>
            </Child>
        )
    }

}

export default FichaDetalle;
