import axios from 'axios';
import {GLOBAL} from "./global";
import {AuthService} from "./index";

export default class MaintenanceTypeServices {
    constructor() {
        //axios default settings
        axios.defaults.baseURL = GLOBAL.api;
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
        //Intercept Axios Response
        const auth = new AuthService();
        axios.interceptors.response.use((response) => {
            return response
        }, (error) => {
            if (error.response.status === 401) auth.logout();
            return Promise.reject(error);
        })
    }

    getMaintenanceType = () => {
        return new Promise((resolve, reject) => {
            axios.get('maintenance_type').then((response) => {
                const {data} = response.data;
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        })
    }
}