import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import './TrackingCallendar.scss';

const TrackingCallendar = props => {
    const { data } = props;
    let inicio=data.fchInicio?data.fchInicio:null;
    let fin =data.fchFin;
    let events = [
        {
            start: inicio,
            end: fin,
            rendering: 'background',
        },
    ];
    // console.log(inicio)

    return(
        <FullCalendar
            // dayRender={(e)=>{
            //     console.log(e)
            // }}

            locales='es'
            events={events}
            defaultDate={inicio}
            rendering='background'
            plugins={[ dayGridPlugin,interactionPlugin]}/>
    )
};

export default TrackingCallendar;