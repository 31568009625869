import React, {Component} from 'react'
import {Modal, Popover,PullHook,Select, ProgressCircular,ProgressBar} from 'react-onsenui';
import UUID from 'uuid/v4';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import MantoHistoria from '../../components/historymanto/historymanto';
import Imgmanto from '../../images/mantenimiento.png';
import './mantohistoria.scss';
import {MaintenanceServices, VehicleServices, VehicleTypeServices,VehicleStatusServices ,MaintenancePriorityServices,MaintenanceTypeServices} from './../../config';
import {Parent} from "../../components/Layout/Layout";
import {ReactDatez} from 'react-datez'
import 'react-datez/dist/css/react-datez.css'

class MantoHistory extends Component {
    maintenance = new MaintenanceServices();
    vehicleType= new VehicleTypeServices();
    priorityManto= new MaintenancePriorityServices();
    maintenanceType = new MaintenanceTypeServices();
    constructor(props) {
        super(props);
        this.state = {
            isLoadingVehicleType:false,
            isLoadingManteType:false,
            isLoadingNeconomico:false,
            isLoadingPriority:false,
            isLoadingForm:false,
            isLoandinPage:false,
            isModalSucess:false,
            isOpen: false,
            valueSelect:"",
            isOpenm: false,
            errorform: {},
            tipoVehiculo: {},
            MantoTypes:{},
            MantoPriorityState:'Sin filtro',
            Vehicules:{},
            MantosByState:{},
            pullHookState: 'initial',
            MantoPriorityList:[],
            MantoPriority:{},
            idMantenimientoTipo:"",
            Prioritys: {},
            statesVehicles:{}
        };
        this.strTitulo = React.createRef();
        this.idTiposVehiculo = React.createRef();
        this.fchInicio = React.createRef();
        this.idMantenimientoTipo = React.createRef();
        this.fchFin = React.createRef();
        this.txtObservaciones = React.createRef();
        this.idVehiculo = React.createRef();
        this.idPrioridad = React.createRef();
        this.formtop=React.createRef();
        const priorityManto = new MaintenancePriorityServices();
        priorityManto.getMaintenancePriority().then((response) => {
            this.setState({Prioritys: response});
        }).catch((error) => {

        });

        const statusVehicle= new VehicleStatusServices();
        statusVehicle.getVehicleStatus().then((response) => {
                this.setState({statesVehicles: response});

            }).catch((error) => {

            });

        // const mantoGetList = new MaintenanceServices();
        // mantoGetList.getMaintenance().then((response) => {
        //     this.setState({MantoPriorityList: response});
        // }).catch((error) => {
        //
        // });

    }
    componentDidMount(){
        const asynGetMaintenanceByStatus  = async () => {
            const idTiposStado =1;
            try{

                const datapeticion = await this.maintenance.getMaintenanceByStatus(idTiposStado);
                this.setState({pullHookState:"initial"});
                this.setState({ MantoPriorityList: datapeticion});
                // this.setState({datapeticion});


            } catch (e) {
                console.error("Problem", e)
            }
        };
        // this.asyncGetMaintenance();
        asynGetMaintenanceByStatus();
    }
    asynGetMaintenanceByStatus = async () => {

        this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.maintenance.getMaintenanceByStatus(1);
            this.setState({pullHookState:"initial"});
            this.setState({ MantoPriorityList: datapeticion});
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }

    };

    asyncGetMaintenance = async () => {
        this.setState({pullHookState: 'action'});
        this.setState({pullHookState: 'action'});
        
        try{

            const datapeticion = await this.maintenance.getMaintenance();
               this.setState({pullHookState: 'initial'});
                this.setState({MantoPriorityList: datapeticion});
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };
///Funciones asincronicas
    asynGetVehicle = async () => {
        // this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.vehicleType.getVehicleType();
            this.setState({isLoadingVehicleType: false});
            this.setState({tipoVehiculo: datapeticion});
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };

    asynGetPriorityManto  = async () => {
        // this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.priorityManto.getMaintenancePriority();
            this.setState({isLoadingPriority: false});
            this.setState({Prioritys: datapeticion});
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };



    asynGetMaintenanceType  = async () => {
        const idTiposManto = this.idTiposVehiculo.current.value;
        // this.setState({pullHookState: 'action'});
        try{

            const datapeticion = await this.maintenanceType.getMaintenanceType(idTiposManto);
            this.setState({isLoadingManteType: false});
            this.setState({idMantenimientoTipo: datapeticion});
            // this.setState({datapeticion});


        } catch (e) {
            console.error("Problem", e)
        }
    };






///
    componentDidUpdate(prevProps, prevState) {
        //esta accion es para rellenar este formulario

        if (this.state.isOpen && !prevState.isOpen) {
            this.setState({isLoadingVehicleType: true});
            this.setState({isLoadingManteType: true});
            this.setState({errorform: {}});
            // console.log(this.fchInicio.current.state.selectedDate);
            this.setState({isLoadingPriority: true});
            this.asynGetVehicle();
            // const vehicleType = new VehicleTypeServices();
            // vehicleType.getVehicleType().then((response) => {
            //     this.setState({tipoVehiculo: response});
            //     this.setState({isLoadingVehicleType: false});
            // }).catch((error) => {
            //
            // });
           this.asynGetPriorityManto();
            // const priorityManto = new MaintenancePriorityServices();
            // priorityManto.getMaintenancePriority().then((response) => {
            //     this.setState({Prioritys: response});
            //     this.setState({isLoadingPriority: false});
            // }).catch((error) => {
            //
            // });
            this.asynGetMaintenanceType();
            // const idTiposManto = this.idTiposVehiculo.current.value;
            // const MantenanceType = new MaintenanceTypeServices();
            // MantenanceType.getMaintenanceType(idTiposManto).then((response) => {
            //     this.setState({idMantenimientoTipo: response});
            //     this.setState({isLoadingManteType: false});
            // }).catch((error) => {
            //
            // });

        }
        // else if(this.state.isOpen ){
        //     console.log("esta abierto")
        // }

    }

    onClose = (e) => {
       const {resetCounter}=this.props.navigator.props;
       resetCounter();
        this.resetForm();
        this.setState({isOpen: false,});
        this.formtop.current.scrollTo(0, 0);

    };
    onCloseModalSucess=(e)=>{
        this.setState({isModalSucess: false,});
        this.resetForm();
    }
    resetForm=(e)=>{
        this.setState({strTitulo:'', tipoVehiculo: {},Prioritys: {},Vehicules: {},idTipoVehiculo: "",idMantenimientoTipo:"",dateInput1:'',dateInput2:''});
        this.setState({pullHookState: 'initial'});
        this.asynGetVehicle();
        this.asynGetPriorityManto();
        this.asynGetMaintenanceType();
        this.txtObservaciones.current.value='';
        this.strTitulo.current.value = '';
        this.fchInicio.current.state.selectedDate='';
        this.fchFin.current.state.selectedDate='';
    };
    addMaintenance = (e) => {
        e.preventDefault();
        this.setState({isLoadingForm: true});
        const maintenance = new MaintenanceServices();
        let strTitulo = this.strTitulo.current.value;
        let idTiposVehiculo = (this.idTiposVehiculo.current.value==0)? null:this.idTiposVehiculo.current.value;
        let fchInicio = this.fchInicio.current.state.selectedDate;
        let fchFin = this.fchFin.current.state.selectedDate;
        let txtObservaciones = this.txtObservaciones.current.value;
        let idVehiculo = (this.idVehiculo.current.value==0)? null:this.idVehiculo.current.value;
        let idPrioridad = (this.idPrioridad.current.value==0)? null:this.idPrioridad.current.value;
        let idMantenimientoTipo =(this.idMantenimientoTipo.current.value==0)? null:this.idMantenimientoTipo.current.value;

        maintenance.postMaintenance(strTitulo,idTiposVehiculo,fchInicio,fchFin,txtObservaciones,
            idVehiculo,idPrioridad,idMantenimientoTipo).then((response) => {
            this.setState({isLoadingForm: false});
            this.setState({ isOpen: false});
            this.setState({isModalSucess: true,});

        }).catch((error) => {

            // console.log(error);
            this.setState({isLoadingForm: false});
            const errorForm = {...this.state.errorform};
            this.setState({
                errorform: {
                    ...error,
                },
            });
        });


    };
    handleChangeTipoVehiculo = e => {
        const {name} = e.target;
        // console.log(e);
        if (name ==='idTiposVehiculo'){
            this.setState({isLoadingNeconomico: true});
            let idTiposVehiculo = this.idTiposVehiculo.current.value;
            const vehicle = new VehicleServices();
            vehicle.getVehicle(idTiposVehiculo).then((response) => {
                this.setState({Vehicules: response});
                this.setState({isLoadingNeconomico: false});

            }).catch((error) => {

            });

        } else {
            const errorform = {...this.state.errorform};
            let currentErrors = Object.keys(errorform)
                .filter((key) => (key !== name))
                .reduce((obj, key) => {
                    obj[key] = errorform[key];
                    // console.log(obj[key]);
                    return obj;
                }, {});


            this.setState({errorform: currentErrors});
        }
    };


    ClickPriority = e=>{
        let idproirity=e.currentTarget.value;
        let prioritytext=e.currentTarget.getAttribute('name');
        const mantoProritylist = new MaintenanceServices();
        this.setState({pullHookState:'action'});
        this.setState({isOpenm: false})
        mantoProritylist.getMaintenanceByPriority(idproirity).then((response) => {

            this.setState({MantoPriorityList: response,MantoPriorityState:'Prioridad: '+prioritytext});
            this.setState({pullHookState:'initial'});
        }).catch((error) => {

        });
    };
    ClickLessFilter=e=>{
        this.asyncGetMaintenance();
        this.setState({MantoPriorityState:'Sin filtro'});
        this.setState({isOpenm: false});
    }


    ClickState = e=>{


        this.setState({pullHookState:"action"});
        this.setState({isOpenm:false});

        let idstate=e.currentTarget.value;
        let stateText=e.currentTarget.getAttribute('name');
        this.setState({MantoPriorityState:'Estado: '+stateText});
       const asynGetMaintenanceByStatus  = async () => {
            const idTiposStado = idstate;
            try{

                const datapeticion = await this.maintenance.getMaintenanceByStatus(idTiposStado);
                this.setState({pullHookState:"initial"});
                this.setState({ MantoPriorityList: datapeticion});
                // this.setState({datapeticion});


            } catch (e) {
                console.error("Problem", e)
            }
        };

        asynGetMaintenanceByStatus();
    };

    handleFchIni = e => {

        this.setState({dateInput1: e});
    };
    handleFchFin = e => {

        this.setState({dateInput2: e});
    };

    render() {

        const {strTitulo, fchInicio, fchFin, txtObservaciones, idPrioridad,idVehiculo ,idTiposVehiculo} = this.state.errorform;
        const tipomantoform=this.state.errorform.idMantenimientoTipo;
        const {tipoVehiculo, Prioritys,Vehicules,idMantenimientoTipo,isLoadingForm,MantoPriorityList,MantosByState,pullHookState,statesVehicles,isLoadingNeconomico, isLoadingVehicleType, isLoadingManteType,isLoadingPriority,isLoandinPage} = this.state;
        const { navigator } = this.props;

        return (
            <Parent navigator={navigator}>
                <PullHook
                    thresholdHeight={130}
                    height={5}
                    onLoad={() => {
                        this.setState({pullHookState: 'action'});
                        this.asynGetMaintenanceByStatus(1);
                    }}
                    fixedContent={false}
                />
                <div className='top_4'>

                    <h2 className="h2manto"><img className="imgmanto" alt="icono-del manto" src={Imgmanto}></img> Mantenimientos</h2>


                </div>
                <div className="cont-filtro">
                    <span onClick={e => {
                        this.setState({target: this.btn, isOpen: true})
                    }}><i className="fa fa-plus icon-plus"></i> Agregar Mantenimiento</span>

                    <span className="fa fa-filter filtro" ref={(btn) => {
                        this.btn = btn;
                    }}
                          onClick={() =>
                              this.setState({target: this.btn, isOpenm: true})
                          }></span>
                    <Popover
                        isOpen={this.state.isOpenm}
                        onCancel={() => this.setState({isOpenm: false})}
                        getTarget={() => this.state.target}
                    >
                        {/*<label >Filtrar: </label>*/}
                          {/*<div onClick={this.ClickOptionFilter} value={1}>Prioridad</div>*/}
                           {/*<div onClick={this.ClickOptionFilter} value={2}>Estado</div>*/}
                        <div>

                            <ul className="cont-categoria" name="idPrioridad">
                                <li className='lifilter3' >Filtrar Por:</li>
                                {/*<li className='lifilter' onClick={this.ClickLessFilter}>Sin filtro</li>*/}


                                <li className='lifilter2'>Prioridad</li>
                                {(Prioritys.length > 0) &&
                                Prioritys.map((data) => (
                                    <li key={data.idPrioridad} name={data.strNombre} value={data.idPrioridad} onClick={this.ClickPriority} className={`bg-importli${data.idPrioridad}`}><span  key={data.idPrioridad} className={`bg-import${data.idPrioridad}`}><span></span></span> <span className="txt-categoria">{data.strNombre}</span>
                                    </li>

                                ))

                                }
                                <li className='lifilter2'>Estado</li>
                                {(statesVehicles.length > 0) &&
                                statesVehicles.map((data) => (
                                    <li key={data.idestadosvehiculo} name={data.strName} value={data.idestadosvehiculo} onClick={this.ClickState} className={`bg-stateli${data.idestadosvehiculo}`}><span  key={data.idestadosvehiculo} className={`bg-state${data.idestadosvehiculo}`}><span></span></span> <span className="txt-categoria">{data.strName}</span>
                                    </li>

                                ))

                                }
                            </ul>
                        </div>
                    </Popover>

                </div>



                <Modal isOpen={this.state.isOpen}>
                    <i className="fas fa-times" onClick={e => {
                        this.setState({ isOpen: false})
                    }}></i>

                    <div className="modal" id="modal">
                        <div className="Modal-content" ref={this.formtop} id="Modal-agregar">
                            {/*<div className="tituloModal">*/}
                                {/*<h2><i className="fa fa-plus icon-plus"></i> Agregar Mantenimiento</h2>*/}
                            {/*</div>*/}
                            <form  onSubmit={this.addMaintenance}>


                                        <ProgressCircular style={{display: (isLoadingForm === true) ? ' block' : 'none'}} className="Circular-form" indeterminate={true}/>

                                       <div style={{display: (isLoadingForm === true) ? ' none' : 'block'}}>
                                        <div className="cont-form"> /*aqui comienza el form*/
                                       <div className="cont-input">
                                                <label>Título</label>
                                                <input ref={this.strTitulo} type="text" name='strTitulo'
                                                       onChange={(e) => this.handleChangeTipoVehiculo(e)}/>

                                            </div>
                                            {strTitulo &&
                                            strTitulo.map((data) => (
                                                <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                            ))

                                            }
                                            <div className="cont-input">
                                                <label>Tipo de vehiculo</label>
                                                {
                                                    isLoadingVehicleType === true ?
                                                        <ProgressBar indeterminate={true}/>
                                                        :
                                                        <select defaultValue={0}  ref={this.idTiposVehiculo}
                                                                 onChange={(e) => this.handleChangeTipoVehiculo(e)} name="idTiposVehiculo"
                                                                 form="carform">
                                                            <option value={0}  disabled={true} >Seleccione un tipo de vehiculo</option>
                                                            {(tipoVehiculo.length > 0) &&
                                                            tipoVehiculo.map((data) => (
                                                                <option key={data.idvehiculostipo}
                                                                        value={data.idvehiculostipo}>{data.strName}</option>
                                                            ))

                                                            }
                                                        </select>
                                                }
                                            </div>
                                            {idTiposVehiculo &&
                                            idTiposVehiculo.map((data) => (
                                                <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                            ))

                                            }

                                            <div className="cont-input">
                                                <label>N° económico</label>
                                                {
                                                    isLoadingNeconomico === true ?
                                                        <ProgressBar indeterminate={true}/>
                                                        :
                                                        <select ref={this.idVehiculo} defaultValue={0} name="idVehiculo" form="carform">
                                                            <option value={0} disabled={true}>Seleccione un Numero economico</option>
                                                            {(Vehicules.length > 0) &&
                                                            Vehicules.map((data) => (
                                                                <option key={data.Vehicules}
                                                                        value={data.idVehiculo}>{data.strPlaca}</option>
                                                            ))

                                                            }
                                                        </select>


                                                }

                                            </div>
                                            {idVehiculo &&
                                            idVehiculo.map((data) => (
                                                <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                            ))

                                            }

                                            <div className="cont-input">
                                                <label>Prioridad</label>
                                                {
                                                    isLoadingPriority === true ?
                                                        <ProgressBar indeterminate={true}/>
                                                        :
                                                        <select ref={this.idPrioridad} defaultValue={0} name="idPrioridad" form="carform"
                                                                onChange={(e) => this.handleChangeTipoVehiculo(e)}>
                                                            <option value={0} disabled={true}>Seleccione una prioridad</option>
                                                            {(Prioritys.length > 0) &&
                                                            Prioritys.map((data) => (
                                                                <option key={data.idPrioridad}
                                                                        value={data.idPrioridad}>{data.strNombre}</option>
                                                            ))

                                                            }
                                                        </select>
                                                }

                                            </div>
                                            {idPrioridad &&
                                            idPrioridad.map((data) => (
                                                <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                            ))

                                            }


                                            <div className="cont-input">
                                                <label>Tipo de mantenimiento</label>
                                                {
                                                    isLoadingManteType === true ?
                                                        <ProgressBar indeterminate={true}/>
                                                        :
                                                        <select name="idTipoMantenimiento" defaultValue={0}  ref={this.idMantenimientoTipo} onChange={(e) => this.handleChangeTipoVehiculo(e)}
                                                                form="carform">
                                                            <option value={0} disabled={true}>Seleccione un tipo de mantenimiento</option>
                                                            {(idMantenimientoTipo.length > 0) &&
                                                            idMantenimientoTipo.map((data) => (
                                                                <option key={data.idMantenimientoTipo}
                                                                        value={data.idMantenimientoTipo}>{data.strNombre}</option>
                                                            ))

                                                            }
                                                        </select>

                                                }

                                            </div>
                                            {tipomantoform &&
                                            tipomantoform.map((data) => (
                                                <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                            ))

                                            }

                                            <div className="cont-input">
                                                <div className="fch-inicio">
                                                    <label>Fecha de inicio</label>
                                                    <ReactDatez yearJump={false} locale={'es'} ref={this.fchInicio} dateFormat='YYYY-MM-DD' name="dateInput1"
                                                                handleChange={(e) => this.handleFchIni(e)}
                                                                value={this.state.dateInput1}/>
                                                </div>
                                                {fchInicio &&
                                                fchInicio.map((data) => (
                                                    <div className="spanerror" style={{color: 'red'}}>{data}</div>
                                                ))

                                                }
                                            </div>
                                            <div className="cont-input">
                                                <div className="fch-fin">
                                                    <label>Fecha fin</label>
                                                    <ReactDatez ref={this.fchFin} locale={'es'}  yearJump={false}   dateFormat='YYYY-MM-DD'  name="dateInput2"
                                                                handleChange={(e) => this.handleFchFin(e)}
                                                                value={this.state.dateInput2}/>
                                                </div>
                                                {fchFin &&
                                                fchFin.map((data) => (
                                                    <span className="spanerror" style={{color: 'red'}}>{data}</span>
                                                ))

                                                }
                                            </div>

                                            {/*<div className="cont-input cont-flex">*/}
                                            {/*<table>*/}
                                            {/*<tbody>*/}
                                            {/*<tr>*/}
                                            {/*<td>*/}
                                            {/*<div className="fch-inicio">*/}
                                            {/*<label>Fecha de inicio</label>*/}
                                            {/*<ReactDatez ref={this.fchInicio} dateFormat='YYYY-MM-DD' name="dateInput1"*/}
                                            {/*handleChange={(e) => this.handleFchIni(e)}*/}
                                            {/*value={this.state.dateInput1}/>*/}
                                            {/*</div>*/}
                                            {/*{fchInicio &&*/}
                                            {/*fchInicio.map((data) => (*/}
                                            {/*<div className="spanerror" style={{color: 'red'}}>{data}</div>*/}
                                            {/*))*/}

                                            {/*}*/}
                                            {/*</td>*/}
                                            {/*<td>*/}
                                            {/*<div className="fch-fin">*/}
                                            {/*<label>Fecha Fin</label>*/}
                                            {/*<ReactDatez ref={this.fchFin}  dateFormat='YYYY-MM-DD'  name="dateInput2"*/}
                                            {/*handleChange={(e) => this.handleFchFin(e)}*/}
                                            {/*value={this.state.dateInput2}/>*/}
                                            {/*</div>*/}
                                            {/*{fchFin &&*/}
                                            {/*fchFin.map((data) => (*/}
                                            {/*<span className="spanerror" style={{color: 'red'}}>{data}</span>*/}
                                            {/*))*/}

                                            {/*}*/}
                                            {/*</td>*/}
                                            {/*</tr>*/}
                                            {/*</tbody>*/}
                                            {/*</table>*/}
                                            {/*</div>*/}

                                            <div className="cont-input">
                                                <label>Observaciones</label>
                                                <textarea ref={this.txtObservaciones}></textarea>
                                            </div>
                                            {txtObservaciones &&
                                            txtObservaciones.map((data) => (
                                                <span>{data}</span>
                                            ))

                                            }

                                        </div> /*aqui termina el form*/
                                        <div className="actions">
                                    <button className="toggle-button btn-cancelar" type="button" onClick={this.onClose} >Cancelar</button>
                                    <button className="btn-aceptar" type="submit"><i
                                    className="fa fa-edit"></i> Agregar
                                    </button>
                                    </div>
                                    </div>


                                {/*<div className="actions">*/}
                                    {/*<button className="toggle-button btn-cancelar" type="button" onClick={this.onClose} >Cancelar</button>*/}
                                    {/*<button className="btn-aceptar" type="submit"><i*/}
                                        {/*className="fa fa-edit"></i> Agregar*/}
                                    {/*</button>*/}
                                {/*</div>*/}
                            </form>

                        </div>
                    </div>
                </Modal>
                <Modal isOpen={this.state.isModalSucess}>
                    <div className="modalHistoria">
                        <div className="modal-content-historia">
                            <div className="cont-form">
                                <i className="fas fa-check check"></i>
                                <p className="spanModoalSucces">El mantenimiento se ha programado</p>
                                <div className="alert-dialog-footer">
                                    <button className="btn-aceptar" onClick={this.onCloseModalSucess}> Aceptar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <span className="SpanFilter">
                    Filtrado: {this.state.MantoPriorityState}
                </span>
                { this.state.pullHookState === 'action' ?
                    <ProgressCircular className="Circular-MantoHistoria" indeterminate={true}/>
                    :
                    MantoPriorityList.length>0?
                    MantoPriorityList.map((data)=>{

                    let id = UUID();
                    return (

                        <MantoHistoria
                            key={id}
                            navigator={navigator}
                            datos={data}

                        />

                    )
                }):
                        <div>
                            <h3>Actualmente no se encuentra ningún registro de mantenimiento</h3>
                        </div>
                }
            </Parent>
        )
    }

}

export default MantoHistory;
