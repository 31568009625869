import React from 'react';
import './fichamanto.scss';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import LogoManto from '../../images/mantenimiento.png'

const Fichamanto  = props => {

    const datos=props.datos;
    const idPrioridad = (datos.idPrioridad) ? datos.idPrioridad.idPrioridad : null;
    console.log(datos)
    return (
        <div>
            <div className="flex-titulo titulo">
                <h2 className="tituloh2"><img src={LogoManto} alt="" className="tituloimg m"/> {datos.strTitulo} </h2>
            <div className={`bg-${idPrioridad}-important`}><span></span></div>
            </div>
            <div className="item-ficha">
                <div className="cont-info-ficha">

                    <ul>
                        <li className="text-order">N° orden: {datos.strFolio}</li>
                        <li>Fecha Inicio: <b>{datos.fchInicioText}</b></li>
                        <li>Fecha fin: <b>{datos.fchFinText}</b></li>
                        <li>
                            <p>{datos.txtObservaciones}</p>
                        </li>
                    </ul>

                </div>

                <div className={`bg-${datos.image}-ficha opacity`}></div>
            </div>
        </div>);
}
export default Fichamanto