import React, {Component} from 'react'
import {Toolbar, ToolbarButton, BackButton,AlertDialog,AlertDialogButton} from 'react-onsenui';
import 'onsenui/css/onsenui.css';
import Logo from '../../images/logo.png'
import 'onsenui/css/onsen-css-components.css'
import './navbar.scss';
import AuthServices from './../../config/AuthServices';
import {Login, Main} from './../../page';
import uuid from 'uuid/v4';

const toolbarStyle = {
    background: '#000000',
};

class Navbar extends Component {

    state = {
        openDiaLogOut: false,
        

    };
    handleDialogLogOut = (finish = false) => {
        if (finish) {
            this.logout();
            // const {id} = this.props;
            // this.maintenance.deleteMaintenance(id).then((response) => {
            //     this.setState({data: response});
            // })
        }
        this.setState({openDiaLogOut: false}, () => {
            // if (finish) {
            //     console.log('hiciste finish 2');
            //     // //Reiniciamos el contador, llamando la funcion desde los props de nuestro Padre. Navigator
            //     // const {resetCounter} = this.props.navigator.props;
            //     // resetCounter();

            //     // setTimeout(() => {
            //     //     //Esperamos 500ms para autocerrar la ventana
            //     //     this.navigator.popPage(this);
            //     // }, 500);
            // }
        });
    };
    logout = () => {

        const auth = new AuthServices();
        const {navigator} = this.props;
        auth.logout();
        navigator.resetPage({component: Login, props: { key: uuid() }});
        // navigator.pushPage({component: Login});
    };

    back = component => {
        const {navigator} = this.props;
        navigator.popPage({component: component});
    };

    render() {
        const {backbutton, logout, title} = this.props;
        const {openDiaLogOut} = this.state;
        return (
            <React.Fragment>
            <Toolbar style={toolbarStyle}>
                <div className="left">
                    {backbutton ?
                        <BackButton/> :
                        <img className="logo-header" src={Logo}/>
                    }
                </div>

                <div className="center" style={{color:'#fff'}}>
                    { (title) ? title : '' }
                </div>

                <div className="right">
                    {logout &&
                    <ToolbarButton onClick={() => this.setState({openDiaLogOut: true})} className="material-icons icon-exit"
                                   icon="fa-sign-out-alt"></ToolbarButton>
                    }
                </div>
            </Toolbar>
            <AlertDialog isOpen={openDiaLogOut}>
                    <div className="alert-dialog-title">Alerta!</div>
                    <div className="alert-dialog-content">
                        ¿Estas seguro que deseas Salir de la Aplicación?


                    </div>
                    <div className="alert-dialog-footer">
                        <AlertDialogButton onClick={() => this.handleDialogLogOut()}>
                            No
                        </AlertDialogButton>

                        <AlertDialogButton onClick={() => this.handleDialogLogOut(true)}>
                            Si
                        </AlertDialogButton>
                    </div>
                </AlertDialog>
            </React.Fragment>
        )
    }
};

export default Navbar;
