import React, {Component} from 'react'
import {List, PullHook, Icon, ListItem, ListHeader, Modal,ProgressCircular} from 'react-onsenui';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import {Notifiacion, Parent} from '../../components';
import './notificaciones.scss';
import uuid4 from 'uuid/v4';
import {MaintenanceServices, NotificationServices} from "../../config";
import notifyimg from '../../images/img-notificacion.png';

const notifStyle = {
    marginLeft: '46%',
    marginTop: '68%',
};

class Notificaciones extends Component {

    notification = null;
    maintenance = new MaintenanceServices();
    state = {
        notifications: [],
        pullHookState: 'initial',
        LoadingNotif:false,
    };

    constructor(props) {
        super(props);
        this.notification = new NotificationServices();
        this.asyncNotify();
    }


    componentDidUpdate(prevProps, prevState) {

        if(prevProps.navigator.props.isCounterReset){

            this.asyncNotify();
        }
    }

    asyncGetMaintenance = async () => {
        const {id} = this.props;
        try{
            const data = await this.maintenance.getMaintenanceID(id);
            this.setState({data});
            this.setState({LoadingNotif: false});
        } catch (e) {
            console.error("Problem", e)
        }
    };

    asyncNotify = async () => {
        try {
            const notifications = await this.notification.getNotifications();
            this.setState({notifications});
            this.setCounter(notifications.length);
            this.setState({LoadingNotif: false});
            this.setState({pullHookState: 'initial'});
        } catch (e) {
            console.error("Problem", e)
        }
    };

    setCounter = count => this.props.updateCounterNotify(count);

    openPageChild = (id, key, Mensaje = null) => {
        const {navigator} = this.props;
        const title = `Mantenimiento ${key}`;
        const finish = (Mensaje === 'Finalizar' || Mensaje === 'Inicia y Finaliza');
        navigator.pushPage({component: Notifiacion, props: {key, id, title,finish}});
    };


    render() {
        const {notifications, pullHookState,LoadingNotif} = this.state;
        const {navigator} = this.props;



        return (
            <Parent navigator={navigator}>
                {/*<div className="titulo">
                        <h2>Notificaciones</h2>
                    </div>*/}
                <PullHook
                    thresholdHeight={130}
                    height={5}
                    onLoad={() => {
                        this.setState({LoadingNotif: true});
                        this.asyncNotify();
                    }}
                    fixedContent={false}
                />
                {
                    LoadingNotif === true ?
                        <ProgressCircular style={notifStyle} Classname="Circular-notif" indeterminate={true}/>
                        :
                        notifications.length>0?
                            <div>
                                <div className={'top_4'}><h2 className={'h2manto'}><i className="fa fa-bell-o"></i>Notificaciones</h2></div>

                            <List

                            dataSource={notifications}
                            renderRow={(data, index) => {
                                const {idMantenimiento, Mensaje, strFolio, strTitulo, fchInicio, fchFin} = data;
                                return (
                                    <ListItem key={index}
                                              onClick={() => this.openPageChild(idMantenimiento, strFolio, Mensaje)}>
                                        <div className="left">
                                            <div className="bg-avatar">
                                                <img className="list-item__thumbnail" src={notifyimg}/>
                                            </div>
                                        </div>
                                        <div className="center">
                                            <span className="list-item__title">{Mensaje} {strTitulo} </span>
                                        </div>
                                        <div className="right">
                                            {/*<div className="info-notificacion">*/}
                                                {/*<div className="hora">9:20 AM</div>*/}
                                                {/*<span className="no-leido"></span>*/}
                                            {/*</div>*/}

                                        </div>
                                    </ListItem>
                                )
                            }}
                        /></div>:
                            <div>
                            <div className={'titulo'}><h2><i className="fa fa-bell-o"></i>Notificaciones</h2></div>
                         <h3>No se encuentra disponible ninguna notificación por el momento</h3>
                            </div>



                }
                        {/*<List*/}
                            {/*renderHeader={() => <ListHeader>*/}
                                {/*<div className={'titulo'}><h2><i className="fa fa-bell-o"></i>Notificaciones</h2></div>*/}
                            {/*</ListHeader>}*/}
                            {/*dataSource={notifications}*/}
                            {/*renderRow={(data, index) => {*/}
                                {/*const {idMantenimiento, Mensaje, strFolio, strTitulo, fchInicio, fchFin} = data;*/}
                                {/*return (*/}
                                    {/*<ListItem key={index}*/}
                                              {/*onClick={() => this.openPageChild(idMantenimiento, strFolio, Mensaje)}>*/}
                                        {/*<div className="left">*/}
                                            {/*<div className="bg-avatar">*/}
                                                {/*<img className="list-item__thumbnail" src={notifyimg}/>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="center">*/}
                                            {/*<span className="list-item__title">{Mensaje} {strTitulo} </span>*/}
                                        {/*</div>*/}
                                        {/*<div className="right">*/}
                                            {/*<div className="info-notificacion">*/}
                                                {/*<div className="hora">9:20 AM</div>*/}
                                                {/*<span className="no-leido"></span>*/}
                                            {/*</div>*/}

                                        {/*</div>*/}
                                    {/*</ListItem>*/}
                                {/*)*/}
                            {/*}}*/}
                        {/*/>*/}


                {/*<List*/}
                    {/*renderHeader={() => <ListHeader>*/}
                        {/*<div className={'titulo'}><h2><i className="fa fa-bell-o"></i>Notificaciones</h2></div>*/}
                    {/*</ListHeader>}*/}
                    {/*dataSource={notifications}*/}
                    {/*renderRow={(data, index) => {*/}
                        {/*const {idMantenimiento, Mensaje, strFolio, strTitulo, fchInicio, fchFin} = data;*/}
                        {/*return (*/}
                            {/*<ListItem key={index}*/}
                                      {/*onClick={() => this.openPageChild(idMantenimiento, strFolio, Mensaje)}>*/}
                                {/*<div className="left">*/}
                                    {/*<div className="bg-avatar">*/}
                                        {/*<img className="list-item__thumbnail" src={notifyimg}/>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="center">*/}
                                    {/*<span className="list-item__title">{Mensaje} {strTitulo} </span>*/}
                                {/*</div>*/}
                                {/*<div className="right">*/}
                                    {/*<div className="info-notificacion">*/}
                                        {/*<div className="hora">9:20 AM</div>*/}
                                        {/*<span className="no-leido"></span>*/}
                                    {/*</div>*/}

                                {/*</div>*/}
                            {/*</ListItem>*/}
                        {/*)*/}
                    {/*}}*/}
                {/*/>*/}
                {/*<Modal isOpen={pullHookState === 'action'}>*/}
                    {/*<span><Icon size={35} spin={true} icon='ion-load-d'></Icon> Loading data...</span>*/}
                {/*</Modal>*/}
            </Parent>
        )
    }

}

/*
*
                            */
export default Notificaciones;
