import axios from "axios";
import qs from 'qs'
import { GLOBAL } from './global';

export class AuthService {

    user = null;
    token = null;
    url = null;
    message = '';

    constructor(user){

        this.user = localStorage.getItem('user');
        this.token = localStorage.getItem('token');
        this.url = GLOBAL.api;
        user = this.user

    }

    check = () => {
        return !(!this.user && !this.token);
    };

    login = (user, token) => {

        localStorage.setItem('user',user.username);
        localStorage.setItem('token',token);
    };

    logout = () => {
        localStorage.clear();
    };

    attemp = (username, password) => {
        return new Promise((resolve,reject) => {
            if(username != false && password != false) {
                let headers = {};
                // headers['content-type'] = 'application/json';
                headers['Content-Type'] = 'application/x-www-form-urlencoded';
                // headers['Accept'] = '*';
                // headers['Access-Control-Allow-Origin'] = '*';
                let data = {};
                data.username = username;
                data.password = password;
                data=qs.stringify(data);
                axios.post(`${this.url}/login`,data,headers).then((response) => {
                    let data = response.data;
                    let success = {};
                    success.user = data.user;
                        success.token = `Bearer ${data.token}`;
                        // this.token = `Bearer ${data.token}`;
                        // console.log(data);
                        // this.user = data.user;

                        resolve(success);

                }).catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        let estado=error.response.status;
                        switch (estado) {
                            case 422:
                                let msjerror=error.response.data.message;

                                break;
                            default:

                                break;
                        }

                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js

                    } else {
                        // Something happened in setting up the request that triggered an Error

                    }
                    reject(error);
                });
            } else reject(false);
        })
    };


}
export default AuthService;
