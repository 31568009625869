import axios from 'axios';
import qs from 'qs';
import {GLOBAL} from "./global";
import {AuthService} from "./index";

export default class MaintenanceServices {

    constructor() {
        //axios default settings
        axios.defaults.baseURL = GLOBAL.api;
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
        //Intercept Axios Response
        const auth = new AuthService();
        axios.interceptors.response.use((response) => {
            return response
        }, (error) => {
            if (error.response.status === 401) auth.logout();
            return Promise.reject(error);
        })
    }

    getMaintenanceCalendar = (groupType, dateselected = false) => {
        return new Promise((resolve, reject) => {
            let routedate = (dateselected) ? `/${dateselected}` : '';
            axios.get(`maintenance/calendar/${groupType}${routedate}`).then((response) => {
                const {data} = response.data;
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        });
    };

    getMaintenanceID = (idMantenimiemnto) => {
        return new Promise((resolve, reject) => {
            axios.get(`maintenance/${idMantenimiemnto}`).then((response) => {
                const data = response.data.data[0];
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        })
    };

    getMaintenance = (idMantenimiemnto) => {
        return new Promise((resolve, reject) => {
            axios.get(`maintenance`).then((response) => {
                const {data} = response.data;
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        })
    };
    getMaintenanceByPriority = (idPrioridad) => {
        return new Promise((resolve, reject) => {
            axios.get(`maintenance/priority/${idPrioridad}`).then((response) => {
                const {data} = response.data;
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        })
    };

    getMaintenanceByStatus = (idPrioridad) => {
        return new Promise((resolve, reject) => {
            axios.get(`maintenance/status/${idPrioridad}`).then((response) => {
                const {data} = response.data;
                resolve(data);
            }).catch((error) => {
                reject(error);
            })
        })
    };
    postMaintenance = (strTitulo, idTiposVehiculo, fchInicio, fchFin, txtObservaciones, idVehiculo, idPrioridad,idMantenimientoTipo) => {
        return new Promise((resolve, reject) => {

            let data = {};
            data.strTitulo = strTitulo;
            data.idTiposVehiculo = idTiposVehiculo;
            data.fchInicio = fchInicio;
            data.fchFin = fchFin;
            data.idMantenimientoTipo=idMantenimientoTipo;
            data.txtObservaciones = txtObservaciones;
            data.idVehiculo = idVehiculo;
            data.idPrioridad = idPrioridad;
            data = qs.stringify(data);

            axios.post('maintenance', data).then((response) => {
                const { message } = response.data;
                // console.log("response");
                resolve(message);
            }).catch((error) => {
                const { message } = error.response.data;
                reject(message);
            })
        })
    };

    putMaintenance = (idMantenimiento,strTitulo, idTiposVehiculo, fchInicio, fchFin, txtObservaciones, idVehiculo, idPrioridad,idMantenimientoTipo) => {
        return new Promise((resolve, reject) => {

            let data = {};
            data.strTitulo = strTitulo;
            data.idTiposVehiculo = idTiposVehiculo;
            data.fchInicio = fchInicio;
            data.fchFin = fchFin;
            data.idMantenimientoTipo=idMantenimientoTipo;
            data.txtObservaciones = txtObservaciones;
            data.idVehiculo = idVehiculo;
            data.idPrioridad = idPrioridad;

            data = qs.stringify(data);

            axios.put(`maintenance/${idMantenimiento}`, data).then((response) => {
                const {message} = response.data;
                resolve(message);
            }).catch((error) => {
                const {message} = error.response.data;
                reject(message);
            })
        })
    };

    deleteMaintenance = (idMantenimiento) => {
        return new Promise((resolve, reject) => {
            axios.delete(`maintenance/${idMantenimiento}`).then((response) => {
                const {message} = response.data;
                resolve(message);
            }).catch((error) => {
                const {message} = error.response.data;
                reject(message);
            })
        })
    }
}