import React, {Component} from 'react';
import {Tab, Tabbar} from 'react-onsenui';
import {Notificaciones, MantoHistory, Home} from './../../page';

class Tabs extends Component {


    state = {
        index: 0,
        notifications:0
    };

    updateCounterNotify = notifications => this.setState({notifications});


  /*  constructor(props){
        super(props);
        console.log('constructor');
        this.notification = new NotificationServices();
        this.asyncNotify();
    }


    componentDidMount() {
        console.log('componentDidMount');
        setTimeout(() => {
            setInterval(() => {
                this.asyncNotify();
            },8000)
        },4000)
    }

    asyncNotify = async () => {
        try {
            const notifications = await this.notification.getNotifications();
            this.setState({notifications});
        } catch (e) {
            console.error("Problem", e)
        }
    };*/


    render() {
        const {navigator} = this.props;
        const { notifications } = this.state;
        return (
            <Tabbar
                onPreChange={({index}) => {
                    this.setState({index})
                }}
                // onPostChange={() => console.log('postChange')}
                // onReactive={() => console.log('postChange')}
                swipeable={true}
                position='bottom'
                index={this.state.index}
                renderTabs={(activeIndex) => [
                    {
                        content: <Home key="home" navigator={navigator} active={activeIndex === 0}/>,
                        tab: <Tab key="inicio" label="Inicio" icon="ion-ios-home-outline"/>
                    },
                    {
                        content: <MantoHistory key="dialogs" navigator={navigator} active={activeIndex === 1}/>,
                        tab: <Tab key="mantenimientos" label="Mantenimientos" icon="ion-wrench"/>
                    },
                    {
                        content: <Notificaciones key="forms" navigator={navigator}
                                                 active={activeIndex === 2} updateCounterNotify={this.updateCounterNotify}/>,
                        tab: <Tab key="forms" className={"testtab"} icon="ion-android-notifications-none"
                                  label="Notificación" badge={((notifications > 0) ? notifications : '')}
                        />

                    },
                ]
                }
            />
        );
    }
}


export default Tabs;
