import React from 'react';
import { Page } from 'react-onsenui';
import { Navbar } from './../../components';
import { AuthService } from "../../config/AuthServices";
import { Login } from "../../page";
import uuid from "uuid/v4";

export const Parent = ({ children, navigator }) => {
    // console.log(navigator);
    const auth = new AuthService();
    if(!auth.check()) {
        navigator.resetPage({component: Login, props: { key: uuid() }});
        return null
    }
    const nav = navigator;
    return (
        <Page contentStyle={{padding: 10}} renderToolbar={() => <Navbar
            logout={true}
            navigator={nav}
        />}>
            { children }
        </Page>
    );
};

export const Child = ({ children, navigator, title }) => {
    const nav = navigator;
    return(
            <Page contentStyle={{padding: 10}} renderToolbar={() => <Navbar
                backbutton={true}
                navigator={nav}
                title={title}
            />}>

                { children }
            </Page>
    )
};
