import React, {Component} from 'react'
import FullCalendar from '@fullcalendar/react'
import {ProgressCircular} from 'react-onsenui';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import './callendar.scss';
import {Slidermanto} from "../../components"

import {
    MaintenancePriorityServices,
    MaintenanceServices,
    MaintenanceTypeServices, NotificationServices,
    VehicleTypeServices
} from "../../config";
import UUID from 'uuid/v4';

class Callendar extends Component{
    datos =null;
    state={
        data:[],
        detail:[],
        isLoadingSlider:false,
        isOpen: false,
        date:false,
        stadoRequest:this.props.stateRequest
    };

      // dateFormant= (fecha)=>{
      //
      //     const realMonth=fecha.getMonth()+1;
      //     const day=(fecha.getDate()<10)?'0'+fecha.getDate():fecha.getDate();
      //     const month=(realMonth<10)?'0'+realMonth:realMonth;
      //
      //     let date = [fecha.getFullYear(),month,day];
      //     date=date.join('-')
      //     return date
      // }
    // componentDidMount(){
    //     this.getManto();
    // }


    // constructor(props){
    //     super(props)
    //     const date=new Date();
    //     const currentMonth=this.dateFormant(date);
    //
    //     this.state.currentMonth = currentMonth;
    // }


    // shouldComponentUpdate(nextProps, nextState) {
    //     // console.log(`${nextState.date} !== ${this.state.date} = ${(nextState.date!== this.state.date)}`);
    //             const ArrayNextStateFolio=nextState.detail[0]==undefined?null:nextState.detail[0];
    //             const ArrayFolioState=this.state.detail[0]==undefined?null:this.state.detail[0];
    //             // console.log(nextState.date)
    //             // console.log(this.state.date)
    //             const stateDetail=ArrayFolioState==null?null:ArrayFolioState.strFolio;
    //             const nextStateDetail=ArrayNextStateFolio==null?null:ArrayNextStateFolio.strFolio;
    //             if (nextStateDetail!==stateDetail || nextState.date!==this.state.date) {
    //
    //                 // this.setState({isLoadingSlider:true});
    //                 return true
    //             } else {
    //                 const SliderConst=this.state.isLoadingSlider==undefined?this.state.isLoadingSlider=null:this.state.isLoadingSlider;
    //                 this.state.isLoadingSlider=SliderConst;
    //
    //                 return false;
    //             }
    //     if(nextProps.currentMonth !== this.props.currentMonth || nextState.date !== this.state.date){
    //
    //         return true;
    //     } else {
    //
    //         return false;
    //     }
    // }


    getManto = () => {
        const {stadoRequest}=this.state;
        const M = new MaintenanceServices();
        M.getMaintenanceCalendar(stadoRequest).then((response)=>{

            this.setState({data:response})
            // console.log(props);
        }).catch((error) => {

        });
    };
    switchStadoRequest=(arg)=>{

        this.setState({stadoRequest:arg,isOpen: false}, () => {
            this.asyncGetMaintenance();
        })
    };
    getMantoDate = () => {

        this.setState({isLoadingSlider:true});
        const {stadoRequest,date}=this.state;

        const M = new MaintenanceServices();
        M.getMaintenanceCalendar(stadoRequest, date).then((response)=>{
            this.setState({isLoadingSlider:false});
            if(response.length<1){
                console.log(this.state.isLoadingSlider)
               this.setState({isLoadingSlider:false})
            }
            this.setState({detail:response})
            // console.log(props);
        }).catch((error) => {
            this.setState({isLoadingSlider:false});
            // console.log(error)
        });
    };
    handleDateClick = (arg) => { // bind with an arrow function
        this.setState({date:arg.dateStr}, () => {
            this.getMantoDate()
        })
    };


    render() {
        const {navigator, data,stadoRequest}=this.props;
        const {detail,isLoadingSlider}=this.state;

        return (
            <div>

                <FullCalendar
                    classname="calendarhome"
                    // datesRender={(e)=>{
                    //     const {currentStart}=e.view;
                    //     this.props.changeMonth(currentStart);
                    //
                    // }
                    // }
                    // defaultDate={this.props.currentMonth}
                    locales='es'
                    events={data}
                    dateClick={this.handleDateClick}
                    plugins={[ dayGridPlugin,interactionPlugin]}/>
                { (this.state.isLoadingSlider === true) ?
                    <ProgressCircular className="Callendar-circular" indeterminate={true}/>
                    :
                    (this.state.detail.length === 0) ?
                        <p className='parrafotexthome'>Actualmente seleccionó el día {this.state.date} y no cuenta con un mantenimiento programado</p>
                        :

                    detail.map((data)=>{
                        let id = UUID();
                        return (
                            <Slidermanto
                                key={id}
                                datos={data}
                                navigator={navigator}
                            />

                        )
                    })}





            </div>
        )
    }

}

export default Callendar;

